<template>
  <div>
    <v-container fluid class="pa-0 mt-0">
      <v-form ref="form">
        <div class="ml-4 mt-4">
          <h3 width="100" height="24" class=".text-lg-h6">
            Task Reports
          </h3>
        </div>
        <v-card class="mx-5 my-5 custom-card">

          <v-row class="ma-0 pa-4 align-center">
            <v-layout row wrap class="ma-0">
              <v-flex xs12 sm4 lg3 md3 class="ma-0">
                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                  offset-y max-width="290px" min-width="auto" hide-details=true>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field style="max-width: 300px" outlined label="From Date" v-model="dateFormatted" dense
                      show-current persistent-hint append-icon="mdi-calendar" v-bind="attrs" hide-details=true
                      v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title :allowed-dates="
                    (date) => date <= new Date().toISOString().substr(0, 10)
                  " @input="menu1 = false"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm4 lg3 md3>
                <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                  max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field style="max-width: 300px" v-model="computedDateFormatted" label="To Date" outlined
                      persistent-hint dense append-icon="mdi-calendar" readonly v-bind="attrs" hide-details=true
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :allowed-dates="
                    (date) => date <= new Date().toISOString().substr(0, 10)
                  " v-model="dueDate" no-title @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm4 lg3 md3>
                <v-btn width="80" height="40" color="primary" @click="searchData()">
                  search
                </v-btn>
              </v-flex>
              <v-flex xs12 sm4 lg3 md3 class="mt-1 text-end">
                <v-btn height="32" @click="downloadUnicodeCSV(hoursData)" min-width="fit-content" color="primary"
                  outlined class="text-capitalize">
                  Download <v-icon class="mx-2 fsize18">mdi-cloud-download </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>


          </v-row>
        </v-card>
        <v-data-table v-if="this.hoursData.length > 0" :headers="headers" v-bind:items="this.hoursData" :search="search"
          :value="this.hoursData" item-key="name" class="elevation-1 px-4 mx-5 mb-5" id="table"
          @click:row="commentsDialog = true">
          <template slot="this.TaskhourList" slot-scope="">
            <tr>
              <th v-for="(item, index) in this.hoursData" :key="index" class="tableRowHeight"></th>
            </tr>
          </template>
          <template v-slot:item="props">
            <tr @click="showDialog(props.item.emp_id)" class="cursor">
              <td>{{ props.index + 1 }}</td>
              <td class="text-left">{{ props.item.emp_name }}</td>
              <td class="text-md-center">{{ props.item.check_in }}</td>
              <td class="text-md-center">{{ props.item.check_out }}</td>
              <td class="text-md-center">{{ props.item.no_of_comments }}</td>
              <td class="text-md-center">{{ props.item.total_office_hours }}</td>
              <td class="text-md-center">{{ props.item.avgofficehours }}</td>
              <td class="text-md-center">{{ props.item.percentage_hours_logged }}%</td>
              <td class="text-md-center">{{ props.item.total_task_hours }}</td>
              <td class="text-md-center">{{ props.item.avgtaskhours }}</td>
              <td class="text-md-center">{{ props.item.percentage_task_hours }}%</td>
              <td class="text-md-center">
                <v-progress-linear height="10" :value="props.item.percentage_task_hours"
                  :color="props.item.percentage_task_hours == 0 ? 'red darken-1' : props.item.percentage_task_hours <= 25 ? 'deep-orange accent-3' : props.item.percentage_task_hours < 50 ? 'indigo darken-1'  : props.item.percentage_task_hours < 70 ? 'blue accent-4' : props.item.percentage_task_hours < 90 ? 'yellow': props.item.percentage_task_hours < 99 ? 'black' : props.item.percentage_task_hours >= 100 ? 'teal lighten-1' : ''"
                  striped></v-progress-linear>
              </td>
            </tr>
          </template>
        </v-data-table>

        <v-dialog v-model="commentsDialog" max-width="720" height="300">

          <v-card>
            <v-card-title class="headline justify-center white--text text" style="background-color: #0D263B;">Show
              Comments</v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-form ref="form">
                  <div style="color: #000000;">
                    <ul v-if="currentComment.length > 0">
                      <li class="blue--text my-2" v-for="(i, id) in currentComment" :key="id">{{ i }}</li>
                    </ul>
                    <div style="font-size: 17px;" v-else class="red--text">! Comments not available</div>
                    <label style="font-size: 20px; font-weight: bold;margin-left: 10px; margin-bottom: 8px;"> {{
                    updatecomments.start_Date }}<span class="float-right">{{ updatecomments.end_Date
                      }}</span></label>
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed color="error" @click="close">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-form>

    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    menu1: false,
    menu2: false,
    menu1: false,
    menu2: false,
    Form: false,
    taskDate: "",
    dueDate: "",
    TaskReports: [],
    search: "",
    name: "",
    date: "",
    loader: false,
    searchQuery: null,
    userid: "",
    commentsDialog: false,
    updatecomments: [],
    comments: "",
    end_Date: "",
    start_Date: "",
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "emp_name",
        class: "",
      },
      { text: "Name", value: "emp_name ", sortable: false },
      {
        text: " Check Ins",
        value: "check_in",
        align: "center",
        sortable: false,
      },
      {
        text: "Check Outs",
        value: "check_out",
        align: "center",
        sortable: false,
      },

      {
        text: "# Comment",
        value: "no_of_comments",
        align: "center",
        sortable: false,
      },
      {
        text: "Office Hrs",
        value: "total_office_hours",
        align: "center",
        sortable: false,
      },
      {
        text: "Avg/Day",
        value: "avgofficehours",
        align: "center",
        sortable: false,
      },
      {
        text: "% Hrs",
        value: "percentage_hours_logged",
        align: "center",
        sortable: false,
        percentage_hours_logged: false,
      },
      {
        text: "Task Time",
        value: "total_task_hours",
        align: "center",
        sortable: false,
      },
      {
        text: "Avg/Day",
        value: "avgtaskhours",
        align: "center",
        sortable: false,
      },
      {
        text: "% Hrs",
        value: "percentage_task_hours",
        align: "center",
        sortable: false,
        percentage_task_hours: false,
      },
      {
        text: "Percentage",
        align: "center",
        sortable: false,
      }
    ],
    fromMin: "",
    fromMax: "",
    currentComment: [],

  }),
  computed: {
    ...mapState(["reportItems", "hoursData", "TaskhourList", "allComments"]),
    dateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted() {
      return this.formatDate(this.dueDate);
    },
    hoursData: {
      get() {
        return this.TaskhourList;
      },
      set(val) {
        // console.log(val);
        this.TaskReports = val;
      },
    },
    sortedArray() {
      let sortedRecipes = this.TaskhourList;

      sortedRecipes = sortedRecipes.sort((a, b) => {
        let fa = a.emp_name.toLowerCase(), fb = b.emp_name.toLowerCase();
        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })
    }
  },
  created() {
    if (
      localStorage.getItem("Report Data") != undefined ||
      localStorage.getItem("Report Data") != null
    ) {
      this.TaskhourList = JSON.parse(localStorage.getItem(" Report Data"));
    }
    var today = new Date().toISOString().substr(0, 10);
    this.date = today;

    var toDate = new Date().toISOString().substr(0, 10);
    this.dueDate = toDate;
  },
  mounted() {
    var currentDate = new Date();
    this.fromMin =
      currentDate.getDate() +
      "/" +
      (currentDate.getMonth() + 1) +
      "/" +
      currentDate.getFullYear();
    this.fromMax =
      currentDate.getDate() +
      "/" +
      (currentDate.getMonth() + 1) +
      "/" +
      currentDate.getFullYear();

    this.searchData();
  },
  methods: {
    searchData() {
      var currentDate = new Date(this.date);
      var startDate =
        currentDate.getDate() +
        "/" +
        (currentDate.getMonth() + 1) +
        "/" +
        currentDate.getFullYear();
      var endDate = new Date(this.dueDate);
      var dueDate =
        endDate.getDate() +
        "/" +
        (endDate.getMonth() + 1) +
        "/" +
        endDate.getFullYear();
      let json = {
        currentDate: startDate,
        endDate: dueDate,
        dateProvided: "true",
      };
      let json1 = {
        "start_Date": startDate,
        "end_Date": dueDate
      }
      this.$store.dispatch("getHoursList", json);
      this.$store.dispatch("getcomments", json1);
    },

    showDialog(empid) {
      this.commentsDialog = true
      this.currentComment = [];
      this.allComments.forEach(el1 => {
        if (empid == el1.emp_id) {
          this.currentComment.push(el1.comments)
        }
      });
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      return `${year}/${month.padStart(2, "0")}/${day.padStart(2, "0")}`;
    },
    showComments(data) {
      this.updatecomments = data
      this.commentsDialog = true
    },
    close() {
      this.commentsDialog = false
      this.$refs.form.resetValidation()

      let json = {
        taskid: this.updatecomments.id,
        startDate: this.start_Date,
        endDate: this.end_Date,
        id: this.updatecomments.id

      };

    },
    downloadUnicodeCSV(objArray) {
      var arr = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      var str = `${Object.keys(arr[0]).map((value) => `"${value}"`).join(',')}` + '\r\n';
      var csvContent = arr.reduce((st, next) => {
        st += `${Object.values(next).map((value) => `"${value}"`).join(',')}` + '\r\n';
        return st;
      }, str);
      var element = document.createElement('a');
      element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
      // console.table(csvContent)
      element.target = '_blank';
      element.download = 'Reports.csv';
      element.click();
    },
  },
};
</script>

<style>
.v-data-table {
  border-radius: 12px !important;
}

.custom-card.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 5%) !important;
}

.h-112 {
  height: 112px !important;
}

/* #input_29_ampm {
  display : none !important;
} */
.cursor {
  cursor: pointer !important;
}
</style>
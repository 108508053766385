<template>

  <v-app style="background-color: #0d263b!important">
    <v-form ref="form">
      <v-layout row align-center justify-center ma-0>
        <v-flex xs12 sm12 md6 lg7 xl7 align-center justify-center text-center>
          <div>
            <v-img src="@/assets/icons/loginimage.svg"> </v-img>
          </div>
        </v-flex>
        <v-flex xs12 sm12 md6 lg5 xl5 align-center justify-center>
          <v-layout row warp align-center justify-center class="height: calc(100vh - 48px) !important;">
            <div>

              <div>
                <v-img width="345" height="120" src="@/assets/icons/codiFilogo.jpeg">
                </v-img>
              </div>

              <div class="mb-8 mt-2">
                <h4 class="grey--text text--lighten-1 font-weight-bold">
                  Welcome to CodiFi
                </h4>
              </div>

              <div class="mt-4">
                <label for="" class="grey--text text--lighten-1">Username or Employee ID</label>

                <v-text-field class="cls_input" width="360px" height="48" v-model="usercredential" :rules="nameRules"
                  autocomplete="on" outlined color="white" dense required></v-text-field>

              </div>

              <div class="mt-2">
                <label for="" class="grey--text text--lighten-1">Password</label>
                <v-text-field width="360" height="48" outlined required class="text-green" v-model="password"
                  :rules="passwordRules" style="color: white;" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" dense autocomplete="on">
                </v-text-field>
              </div>

              <div class="text-right">
                <span class="forgotcolor"> Forgot Password ?</span>
              </div>
              <v-btn depressed class="mt-5 text-none" block height="48" color="warning" :loading="loading"
                :disabled="loading" @click="signin()">Sign in
              </v-btn>

              <div class="d-flex justify-center mt-4 clr-000">
                Version 1.1.7
              </div>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
    <div class="text-center">

    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  data: () => ({
    show1: false,
    data: '',
    usercredential: "",
    nameRules: [(v) => !!v || "Username is required.",],
    password: "",
    passwordRules: [(v) => !!v || "Password is required"],
    select: null,
  }),
  computed: {
    ...mapGetters({
      loading: "getLoader",
    }),
  },

  mounted() { },

  methods: {

    signin() {

      if (this.$refs.form.validate()) {
        let jsonData = {
          "emp_email": this.usercredential.trim(),
          "password": this.password
        }
        this.$store.dispatch("userData/userLogin", jsonData)

      }
    },

  }
}
</script>

<style scoped>
.v-text-field {
  width: 360px;
  color: white !important;
}

::v-deep.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px !important;
  margin-bottom: 0px !important;
}

.forgotcolor {
  color: #1971c2;
  font-size: 14px !important;
}

::v-deep .v-btn {
  letter-spacing: 0px !important;
  font-size: 16px !important;
}

::v-deep .v-text-field__slot {
  margin-top: 3px;
}

::v-deep .v-input__append-inner {
  margin: auto 0px !important;
}

.loginInput .v-input input:focus,
.v-input input:active,
.v-input textarea:focus,
.v-input textarea:active {
  outline: none;
  color: white !important
}

.theme--light.v-input input,
.theme--light.v-input textarea {
  color: white !important;
}

.v-text-field--outlined fieldset {
  color: rgb(255, 255, 255) !important;
}

.mdi-eye::before {
  color: white !important;
}

.mdi-eye::after {
  color: white !important;
}

::v-deep .v-input__slot {
  border: 1px solid #ffffff;
  color: #ffffff !important;
}

::v-deep .v-input input {
  color: #ffffff !important;
}

.v-text-field--outlined.v-input--is-focused fieldset,
.v-text-field--outlined.v-input--has-state fieldset {
  border: none !important;
}

.clr-000 {
  color: white !important;
}

.v-card__text {
  margin-top: 32px !important;
  font-size: 16px;
  /* color: #1971c2 !important; */
}
</style>
<template>
  <div>
    <v-container fluid class="pa-0 mt-0">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="mx-5 my-5 custom-card" v-if="taskkForm">
          <v-row class="ma-0 pa-4 align-center lh-80">
            <v-col cols="2" class="pa-0">
              <h3 width="100" height="24" class=".text-lg-h6">Tasks</h3>
            </v-col>
            <v-col class="pa-0" cols="1"></v-col>
            <v-col cols="4" class="pa-0">
              <v-text-field v-model="search" single-line hide-details=true dense outlined
                prepend-inner-icon="mdi-magnify" class="align-search" placeholder="Search"></v-text-field>
            </v-col>
            <v-col class="pa-0 text-end" cols="3">
              <div class="mt-0 float-right">


                <v-radio-group v-model="taskSwitch" class="radio-switch mx-width-300" row hide-details=true dense>
                  <v-radio label="My Task" class="fsize12" value="user" hide-details></v-radio>
                  <v-radio label="All Task" class="fsize12" value="all" hide-details></v-radio>
                </v-radio-group>
              </div>

            </v-col>

            <v-col cols="2" class="pa-0 d-flex justify-end ">
              <v-btn v-if="addTaskbtn" depressed color="align-center justify-center  text-none pa-0 primary "
                height="40" min-width="100px" class="mb-0" @click="addTask()">
                Add Task
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <!-- <v-row>
      
    </v-row> -->
        <!-- <v-row class="ma-0 ml-2" v-if="filterItems">
          <v-col cols="2">
            <label for="">Assingned To</label>
              <v-autocomplete dense @input="selectAssigned($event)" v-model="assignedEmployee" :items="employeRecord" 
                  auto-select-first item-text="emp_name" item-value="emp_id" :value="employeRecord" outlined>
                </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-menu v-model="menu3" :close-on-content-click="false" transition="scale-transition" offset-y
              max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <div class="fsize14">Due Date</div>
                <v-text-field style="max-width: 300px; margin-top: 3px;" v-model="dateFormattedTo" outlined
                  persistent-hint dense append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="toDate" no-title :allowed-dates="
                (date) => date <= new Date().toISOString().substr(0, 10)
              " @input="menu3 = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <label for="">Priority</label>

            <v-select dense v-model="prioritydata" :items="priorityListItems" outlined></v-select>
          </v-col>
          <v-col cols="2">
            <label for="">Status</label>
            <v-select dense v-model="currentstatus" :items="statusListItems" outlined></v-select>
          </v-col>
          <v-col cols="2">
            <v-btn depressed color="align-center justify-center  text-none pa-0 primary " height="40" min-width="100px"
              class="mb-7 mt-22">
              Search
            </v-btn>
          </v-col>

        </v-row> -->

        <div class="mx-5 mb-0" style="background-color:white" v-if="hideTab">
          <v-tabs v-model="tabcontrol" height="36">
            <v-tab height="28" href="#list" color="#ffffff">Tasks</v-tab>
            <v-tab height="28" href="#board" color="#ffffff">Board</v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items class="transparent-body" v-model="tabcontrol">

            <v-tab-item id="list" :transition="false">
              <div class="mt-5 fsize20">

                <v-row fill-height v-if="tasklist">
                  <v-col v-for="column in columns" :key="column.id" :cols="12 / (columns.length || 1)"
                    class="card-column elevation-1">
                    <div :data-id="column.id" class="card-list pa-3">
                      <v-card v-for="(task, index) in column.tasks" :key="task.id"
                        :color="task.locked ? 'grey lighten-2' : 'white'" :class="{ 'locked': task.locked }"
                        class="mb-3">
                        <v-card-title>Data</v-card-title>
                        <v-card-text>Data</v-card-text>
                        <v-card-actions v-if="task.locked">
                          <v-spacer />
                          <v-btn icon @click="openUnlockRequest(task)">
                            <v-icon>mdi-lock</v-icon>
                          </v-btn>
                        </v-card-actions>
                        <v-card-actions v-else>
                          <v-spacer />
                          <v-btn icon @click="editTask(task)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-menu top left>
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="deleteTask(task, column, index)">
                                <v-list-item-title>Delete</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click.prevent>
                                <v-list-item-title>Cancel</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-card-actions>
                      </v-card>
                    </div>
                    <v-btn fab absolute dark color="pink" class="card-add-button" @click="newTask(column.id)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

              </div>
            </v-tab-item>


            <v-tab-item id="board" :transition="false">
              <div class="mt-5 fsize20">

                <div class="user-select-none">
                  <div class=" testimonial-group">
                    <div class="boardRow text-center d-flex  justify-space-evenly">
                      <!-- open column -->
                      <div class="boards mr-3">
                        <v-card elevation="1" class="mx-auto ma-2 d-flex flex-column" min-height="550" width="350">

                          <div
                            class='text-center light-blue lighten-5 cardHead d-flex align-center justify-space-between px-4'>
                            <span class="primaryColor fsize12">Open <span v-if="openTasks.length != 0">({{
                            openTasks.length
                            }})</span></span>
                            <v-icon color="light-blue" @click="addTaskDetails">mdi-plus-circle</v-icon>
                          </div>



                          <div class="overflow-y-auto h-550 allTasks" @drop.prevent="currentStatus = 'Open'">
                            <div class="pa-3 m-h-76">
                              <draggable tag="div" :group="{ name: 'section', put: true }" :list="openTasks"
                                @change="afterAdd(openTasks, $event)" v-bind="dragOptions"
                                :options="{ group: 'authorslist', draggable: '.author' }">
                                <v-card :ripple="false" min-height="76" class="pa-1 my-2 taskCard rounded-lg"
                                  v-for="(t, index) in openTasks" :key="index" @click="alltask(t)">
                                  <div class="d-flex align-center justify-space-between">
                                    <div class="text-left">
                                      <span class="secondaryColor fsize14 ml-2">{{ t.task_name }}</span>
                                    </div>
                                    <v-btn min-width="80" height="24" depressed rounded-lg
                                      :color="t.priority == 'Low' ? 'teal lighten-4 teal--text' : t.priority == 'Medium' ? 'amber lighten-4 orange--text' : t.priority == 'High' ? 'red lighten-4 red--text' : 'grey white--text'"
                                      class="pa-0 text-capitalize mx-1 fsize16">{{ t.priority }}</v-btn>
                                  </div>

                                  <div class="d-flex align-center justify-space-between mt-3">
                                    <div class="secondaryColor fsize12 grey--text ml-2">{{ t.project_name }}</div>
                                    <div class="secondaryColor fsize12 grey--text mr-2">{{ (t.end_date) }}</div>
                                  </div>


                                </v-card>

                              </draggable>
                            </div>
                            <div class="pa-6" v-if="openTasks.length == 0"
                              v-bind:style="openTasks.length == 0 ? '' : 'border: none;'">
                              <img src="@/assets/icons/notask.svg" class="img-width">
                              <div>
                                <p>No Tasks Found</p>
                              </div>
                            </div>
                          </div>


                        </v-card>
                      </div>

                      <!-- In progress column -->
                      <div class="boards mr-3">
                        <v-card elevation="1" class="mx-auto ma-2 d-flex flex-column" min-height="550" width="350">
                          <div
                            class='text-center deep-orange lighten-5 cardHead d-flex align-center justify-space-between px-4'>
                            <span class="primaryColor fsize12">In Progress <span v-if="inProgressTasks.length != 0">({{
                            inProgressTasks.length
                            }})</span></span>
                            <v-icon color="light-blue" @click="addTaskDetails">mdi-plus-circle</v-icon>
                          </div>
                          <div class="overflow-y-auto h-550" @drop.prevent="currentStatus = 'In-Progress'">
                            <div class="pa-3 m-h-76">
                              <draggable tag="div" :group="{ name: 'section', put: true }" :list="inProgressTasks"
                                @change="handleChange2(inProgressTasks)" v-bind="dragOptions" :move="drag_handler">
                                <v-card :ripple="false" min-height="76" class="pa-1 my-2 taskCard"
                                  v-for="(t, tidx) in inProgressTasks" :key="tidx">
                                  <div class="d-flex align-center justify-space-between">
                                    <div class="text-left">
                                      <span class="secondaryColor fsize14 ml-2">{{ t.task_name }}</span>
                                    </div>
                                    <v-btn min-width="80" height="24" depressed rounded-lg
                                      :color="t.priority == 'Low' ? 'teal lighten-4 teal--text' : t.priority == 'Medium' ? 'amber lighten-4 orange--text' : t.priority == 'High' ? 'red lighten-4 red--text' : 'grey white--text'"
                                      class="pa-0 text-capitalize mx-1 fsize16">{{ t.priority }}</v-btn>
                                  </div>
                                  <div class="d-flex align-center justify-space-between mt-3">
                                    <div class="secondaryColor fsize12 grey--text ml-2">{{ t.project_name }}</div>
                                    <div class="secondaryColor fsize12 grey--text mr-2">{{ (t.end_date) }}</div>
                                  </div>
                                </v-card>
                              </draggable>
                            </div>
                            <div class="pa-6" v-if="inProgressTasks.length == 0"
                              v-bind:style="openTasks.length == 0 ? '' : 'border: none;'">
                              <img src="@/assets/icons/notask.svg" class="img-width">
                              <div>
                                <p>No Tasks Found</p>
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </div>

                      <!-- Fixed column -->
                      <div class="boards mr-3">
                        <v-card elevation="1" class="mx-auto ma-2 d-flex flex-column" min-height="550" width="350">
                          <div
                            class='text-center grey lighten-2 cardHead d-flex align-center justify-space-between px-4'>
                            <span class="primaryColor fsize12">Fixed <span v-if="fixedTasks.length != 0">({{
                            fixedTasks.length
                            }})</span></span>
                            <v-icon color="light-blue" @click="addTaskDetails">mdi-plus-circle</v-icon>
                          </div>
                          <div class="overflow-y-auto h-550 allTasks" @drop.prevent="currentStatus = 'Fixed'">
                            <div class="pa-3 m-h-76">
                              <draggable tag="div" :group="{ name: 'section', put: true }" :list="fixedTasks"
                                @change="handleChange3(fixedTasks)" v-bind="dragOptions" :move="drag_handler">
                                <v-card :ripple="false" min-height="76" class="pa-1 my-2 taskCard rounded-lg"
                                  v-for="(t, index) in fixedTasks" :key="index">
                                  <div class="d-flex align-center justify-space-between">
                                    <div class="text-left">
                                      <span class="secondaryColor fsize14 ml-2">{{ t.task_name }}</span>
                                    </div>
                                    <v-btn min-width="80" height="24" depressed rounded-lg
                                      :color="t.priority == 'Low' ? 'teal lighten-4 teal--text' : t.priority == 'Medium' ? 'amber lighten-4 orange--text' : t.priority == 'High' ? 'red lighten-4 red--text' : 'grey white--text'"
                                      class="pa-0 text-capitalize mx-1 fsize16">{{ t.priority }}</v-btn>
                                  </div>
                                  <div class="d-flex align-center justify-space-between mt-3">
                                    <div class="secondaryColor fsize12 grey--text ml-2">{{ t.project_name }}</div>
                                    <div class="secondaryColor fsize12 grey--text mr-2">{{ (t.end_date) }}</div>
                                  </div>
                                </v-card>
                              </draggable>
                            </div>
                            <div class="pa-6" v-if="fixedTasks.length == 0"
                              v-bind:style="openTasks.length == 0 ? '' : 'border: none;'">
                              <img src="@/assets/icons/notask.svg" class="img-width">
                              <div>
                                <p>No Tasks Found</p>
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </div>





                      <!-- completed column -->

                      <div class="boards mr-3">
                        <v-card elevation="1" class="mx-auto ma-2 d-flex flex-column" min-height="550" width="350">
                          <div
                            class='text-center teal lighten-5 cardHead d-flex align-center justify-space-between px-4'>
                            <span class="primaryColor fsize12">Completed <span v-if="completedTasks.length != 0">({{
                            completedTasks.length
                            }})</span></span>
                            <v-icon color="light-blue" @click="addTaskDetails">mdi-plus-circle</v-icon>
                          </div>
                          <div class="overflow-y-auto h-550">
                            <div class="pa-3 m-h-76">
                              <draggable tag="div" @drop.prevent="currentStatus = 'Completed'"
                                :group="{ name: 'section', put: true }" :list="completedTasks" v-bind="dragOptions"
                                @change="handleChange4(completedTasks)" :move="drag_handler">
                                <v-card :ripple="false" min-height="76" class="pa-1 my-2 taskCard"
                                  v-for="(t, tidx) in completedTasks" :key="tidx">
                                  <div class="d-flex align-center justify-space-between">
                                    <div class="text-left">
                                      <span class="secondaryColor fsize14 ml-2">{{ t.task_name }}</span>
                                    </div>
                                    <v-btn min-width="80" height="24" depressed rounded-lg
                                      :color="t.priority == 'Low' ? 'teal lighten-4 teal--text' : t.priority == 'Medium' ? 'amber lighten-4 orange--text' : t.priority == 'High' ? 'red lighten-4 red--text' : 'grey white--text'"
                                      class="pa-0 text-capitalize mx-1 fsize16">{{ t.priority }}</v-btn>
                                  </div>


                                  <div class="d-flex align-center justify-space-between mt-3">
                                    <div class="secondaryColor fsize12 grey--text ml-2">{{ t.project_name }}</div>
                                    <div class="secondaryColor fsize12 grey--text mr-2">{{ (t.end_date) }}</div>
                                  </div>
                                </v-card>
                              </draggable>
                            </div>
                            <div class="pa-6" v-if="completedTasks.length == 0"
                              v-bind:style="completedTasks.length == 0 ? '' : 'border: none;'">
                              <img src="@/assets/icons/notask.svg" class="img-width">
                              <div>
                                <p>No Tasks Found</p>
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </div>

                      <!-- Review column -->

                    </div>
                  </div>

                </div>

              </div>
            </v-tab-item>
          </v-tabs-items>


        </div>
        <form @submit.prevent="saveTask()" method="post">
          <v-row class="mx-5 my-5" v-if="showTask" style=" background-color: white; border-radius: 8px;">
            <v-col cols="12" sm="12" md="7" lg="7">
              <div class="fsize14">Task Name</div>
              <v-text-field :rules="nameRules" dense v-model="taskName" outlined></v-text-field>

              <v-row class="ma-0">
                <v-col cols="12" sm="6" md="6" lg="6" class="pa-0">
                  <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                    offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="fsize14">Start Date</div>
                      <v-text-field v-model="dateFormatted" outlined dense show-current persistent-hint
                        append-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" class="pr-0 pt-0 pb-0">
                  <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                    max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="fsize14">Due Date</div>
                      <v-text-field v-model="computedDateFormatted" outlined persistent-hint dense
                        append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dueDate" no-title @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-col>

              </v-row>
              <v-row class="ma-0">
                <v-col cols="12" sm="6" md="6" lg="6" class="pa-0">
                  <div class="fsize14">Priority</div>
                  <v-select dense v-model="prioritydata" :items="priorityList" outlined></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="pr-0 pt-0 pb-0">
                  <div class="fsize14">Status</div>
                  <v-select dense v-model="currentstatus" :items="statusList" outlined></v-select>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="12" sm="6" md="6" lg="6" class="pa-0">
                  <v-row class="ma-0">
                    <v-col cols="12" sm="6" md="6" lg="6" class="pa-0">
                      <div class="fsize14">Estimated Effort</div>
                      <!-- <v-text-field v-model="estimateTime" dense class="without_ampm" type="time" name="temps[ ]" step="0"
                        :rules="effortDateRules" placeholder="" required outlined></v-text-field> -->
                      <vue-timepicker v-model="estimateTime" :rules="effortDateRules" class="align-time" :class="{
                        'is required':
                          submitted && this.estimateTime == '' && this.estimateTime > 4,
                      }"></vue-timepicker>
                      <div class="fsize12 text-danger marg-t-4" v-if="this.submitted && this.estimateTime == '' && this.estimateTime > 4">
                        Please choose estimate time
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" class="pr-0 pt-0 pb-0">
                      <div class="fsize14">Effort Spent</div>
                      <v-text-field class="without_ampm" v-model="effortTime" dense type="time" suffix="" outlined>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="pr-0 pt-0 pb-0">
                  <v-row class="ma-0">
                    <v-col cols="12" sm="6" md="6" lg="6" class="pa-0 mt-8">
                      <div class="fsize14">Billable</div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" class="pr-0 pt-0 pb-0">
                      <div class="mt-7 f-right">
                        <v-switch v-model="billableToggle" inset></v-switch>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="ma-0">
                <v-col cols="12" sm="6" md="6" lg="6" class="pa-0">
                  <div class="fsize14">Project</div>
                  <v-autocomplete dense v-model="projectdata" @input="projectId($event)" :items="projecttList" outlined
                    :rules="inputRule" item-text="project_name" item-value="project_id"
                    @change="getManager(projectdata)">
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" class="pr-0 pt-0 pb-0">
                  <div class="fsize14">Manager</div>
                  <v-text-field style="cursor: pointer" v-model="managerdata" dense required disabled outlined>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="ma-0">
                <v-col cols="12" sm="6" md="6" lg="6" class="pa-0">
                  <div class="fsize14">Assigned To </div>
                  <v-autocomplete dense @input="selectAssigned($event)" v-model="assignedEmployee"
                    :items="employeRecord" auto-select-first item-text="emp_name" item-value="emp_id" outlined>
                  </v-autocomplete>

                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="pr-0 pt-0 pb-0">
                  <div class="fsize14">Choose File</div>
                  <v-file-input dense append-icon="mdi-folder-open" prepend-icon="" outlined></v-file-input>
                </v-col>
              </v-row>

            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="12" md="6" lg="5">
              <div class="fsize14">Description</div>
              <v-textarea dense v-model="descriptionDetail" outlined :rules="descriptionRule" name="input-0-8"
                height="440" value=""></v-textarea>
              <v-row class="ma-0">
                <v-col cols="12" sm="5" md="5" lg="5" class="pa-0"> </v-col>
                <v-col cols="12" sm="7" md="7" lg="7" class="pr-0 pt-0 pb-0">
                  <div class="float-right">
                    <v-btn width="80" height="40" color="error" @click="caneclTask()"> CANCEL </v-btn>
                    <span class="ml-2"></span>
                    <v-btn width="80" height="40" color="primary" :disabled="this.estimateTime == '' ||this.estimateTime == '00:00' || minute-interval == ''" @click="saveTask()">
                      SAVE
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </form>
        <v-data-table v-if="tableList" :headers="headers" :items="taskSwitch == 'all' ? totalTasks : currentUserTasks"
          :search="search" item-key="name" class="elevation-1  mx-5 mb-5 row-pointer custom-table" id="table">
          <template v-slot:item="props">
            <tr class="pa-0" @click="updateTask(props.item)">
              <td>{{ props.index + 1 }}</td>
              <td>{{ props.item.task_name }}</td>
              <td>{{ props.item.project_name }}</td>
              <td>{{ props.item.assigned_to_name }}</td>
              <td>{{ props.item.start_date }}</td>
              <td>{{ props.item.end_date }}</td>
              <td class="text-center"><label
                  :class="props.item.priority == 'High' ? 'greenColorCompleted' : props.item.priority == 'Low' ? 'LowColor' : props.item.priority == 'Medium' ? 'mediumColor' : props.item.priority == 'Critical' ? 'blueColor' : ''">{{
                  props.item.priority
                  }}</label></td>
              <td class="text-left"><label>{{ props.item.task_status }}</label></td>
            </tr>
          </template>
        </v-data-table>
        <!-- <div v-if="currentUserTasks.length == 0">
            <img src="@/assets/icons/notask.svg" class="img-width">
        </div> -->
        <v-dialog v-model="dailog" max-width="720" persistent elevation="0" flat>

          <v-card>
            <v-card-title class="headline justify-center white--text text bg-voilet">Update
              Task</v-card-title>

            <v-card-text>
              <v-container fluid>
                <v-form ref="updateForm" lazy-validation>
                  <div class="d-flex justify-space-between ">

                    <div class="fsize16 font-bold ml-3 mb-2 mx-width-320">
                      {{ updateTaskData.task_name }}
                    </div>
                    <div class="float-right font-bold">
                      {{ updateTaskData.project_name}}
                    </div>
                  </div>


                  <v-row>
                    <v-col class="pt-0 pb-0 mt-4" cols="12" sm="12" md="12" lg="12">
                      <div>Comment</div>
                      <v-textarea dense v-model="commentdata" outlined :rules="commentRule" name="input-0-8"
                        height="100" value=""></v-textarea>
                    </v-col>

                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0" cols="12" sm="6" md="6" lg="6">
                      <div>Actual Effort</div>
                      <!-- <v-text-field v-model="actualTime" dense value="00:00" type="time" class="without_ampm" id="input_29_ampm" name="temps[ ]" required
                        :rules="actualEffortRule" outlined></v-text-field> -->
                      <vue-timepicker hide-clear-button  v-model="actualTime"  value-type="time"  :class="{
                         'is-valid':
                         timeValid || this.actualTime == '' || this.actualTime == '00:00' || this.actualTime == 'hh:mm',
                      }"></vue-timepicker>
                      <!-- <div class="fsize12 text-danger marg-t-4" v-if="this.timeValid || this.actualTime == '' || this.actualTime == '00:00' || this.actualTime == 'hh:mm'">
                        Please choose actual effort
                      </div> -->
                    </v-col>
                    <div>

                    </div>
                    <v-col class="pt-0 pb-0" cols="12" sm="6" md="6" lg="6">
                      <div>Assigned To</div>
                      <v-autocomplete dense @input="selectAssigned($event)" v-model="assignedEmployee"
                        :items="employeRecord" item-text="emp_name" item-value="emp_id" :rules="assignedRule" required
                        outlined></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0" cols="12" sm="6" md="6" lg="6">
                      <div>Priority</div>
                      <v-autocomplete v-model="priorityData" dense :items="priorityList" :rules="priorityRules"
                        outlined></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12" sm="6" md="6" lg="6">
                      <div>Status</div>
                      <v-autocomplete dense v-model="statusData" :items="UpadateStatusList" :rules="statusRules"
                        outlined></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0" cols="12" sm="6" md="6" lg="6">
                      <div class="fsize14">Choose File</div>
                      <v-file-input dense append-icon="mdi-folder-open" prepend-icon="" outlined></v-file-input>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed color="error" @click="close">
                Cancel
              </v-btn>
              <v-btn depressed color="primary" :disabled="actualTime == '' || actualTime == '00:00'" @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import toaster from '../../mixins/expire'
import draggable from "vuedraggable";
import TaskCard from '../../components/notification/taskboard/taskcards.vue'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
export default {
  mixins: [toaster],
  components: {
    TaskCard,
    draggable,
    VueTimepicker
  },
  data: (vm) => ({

    menu1: false,
    menu2: false,
    menu3: false,
    hideTab: true,
    search: '',
    taskName: "",
    effortTime: "",
    actualTime: '',
    estimateTime: "",
    dueDate: "",
    date: "",
    prioritydata: "High",
    currentstatus: "Open",
    // assignedData: '',
    assignedEmployee: "",
    projectdata: "",
    priorityData: '',
    statusData: '',
    assignedValue: "",
    commentdata: "",
    descriptionDetail: "",
    billableToggle: true,
    showTask: false,
    dailog: false,
    tableList: true,
    valid: false,
    name: '',
    updateTaskData: [],
    tasklist: false,
    addTaskbtn: true,
    timeValid: false,
    UpadateStatusList: [
      "Open",
      "Open-Priority",
      "In-Progress",
      "Fixed",
      "Review",
      "Completed",
      "Cancelled",
      "Closed Duplicate",
      "Closed-Rejected",
      "Closed-Absolute",
      "Critical"
    ],

    statusList: ["Open", "Open-Priority", "Critical"],
    statusListItems: ["Open", "Open-Priority", "Critical"],
    priorityList: ["High", "Low", "Medium", "Critical"],
    headers: [
      { text: "S.NO", align: "start", sortable: true, value: "task_name", class: "", },
      { text: "Task", value: "task_name", sortable: true },
      { text: "Project", value: "project_name", sortable: true },
      { text: "Assigned To", value: "assigned_to_name", sortable: true },
      { text: "Created On", value: "start_date", sortable: true },
      { text: "Due Date", value: "end_date", sortable: true },
      { text: "Priority", align: 'center', value: "priority", sortable: true },
      { text: "Status", align: 'left', value: "task_status", sortable: true },
    ],
    priorityList: ['Critical', 'Medium', 'High', 'Low'],
    priorityListItems: ['Critical', 'Medium', 'High', 'Low'],
    inputRule: [
      v => !!v || 'Projectname is required',
    ],
    nameRules: [
      v => !!v || 'Taskname is required',
    ],
    commentRule: [
      v => !!v || 'Comments is required',
      v => v.length >= 5 || "Comments must be minimum 5 characters",
    ],
    assignedRule: [
      v => !!v || 'Assigned To is required',
    ],
    priorityRules: [
      v => !!v || 'Priority is required'
    ],
    statusRules: [
      v => !!v || 'Status is required'
    ],
    descriptionRule: [
      v => !!v || 'Description is required',
      v => v.length >= 20 || "Description must be minium 20 characters",
    ],
    effortDateRules: [
      v => !!v || 'Estimate Effort is required',
    ],
    actualEffortRule: [
      v => !!v || 'Actual Effort is required',
    ],
    ShowList: [],
    closeOnContentClick: false,
    searchAssignedData: '',
    projectdrop: '',
    searchQ: null,
    customedrop: '',
    statusdrop: '',
    prioritydrop: '',
    tabcontrol: 2,
    taskSwitch: "user",
    columns: [],
    toDate: "",
    isAdmin: false,
    filterItems: true,
    assignedData: "",
    taskID: '',
    actualHour: "",
    prorityStatus: "",
    taskType: "",
    uniqueID: "",
    taskkForm: true,
    submitted: false

  }),
  computed: {
    ...mapState("creatUserTask", ["projectData", "projects",]),
    ...mapState(["managerName", "employeeList", "taskLists", "projectNames", 'individualTaskList', 'customerNames', 'projecttList', 'employeRecord']),
    ...mapGetters("userData", ["getEmpId", 'getEmpName']),
    dateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted() {
      return this.formatDate(this.dueDate);
    },
    dateFormattedTo() {
      return this.formatDate(this.toDate);
    },
    ...mapGetters("task", {
      totalTasks: "getAllTasks",
      currentUserTasks: "getCurrentUserTaks",
      currentTasks: "getCurrentTasks",
      //task types
      openTasks: "getOpenTasks",
      inProgressTasks: "getInProgressTasks",
      fixedTasks: "getFixedTasks",
      cancelledTasks: "getCancelledTasks",
      pendingTasks: "getPendingTasks",
      reviewTasks: "getReviewTasks",
      completedTasks: "getCompletedTasks",
    }),
    ...mapGetters({ loader: "getLoader", currentTask: "getCurrentTask" }),
    ...mapGetters("project", { projectList: "getProjectsList" }),
    ...mapGetters("employee", { employeeList: "getEmployeeList" }),
    ...mapGetters({ sidePanelState: "getUpdateTaskPanel" }),

    dragOptions() {
      return {
        animation: 300,
        group: "cards",
        disabled: false,
        draggable: ".taskCard",
        ghostClass: "ghost",
        forceFallback: true,
      };
    },
    managerdata: {
      get() {
        return this.managerName
      },
      set(value) {
        console.log(value)
      }
    },

  },
  watch: {
    // date(val) {
    //   this.dateFormatted = this.parseDate(this.date);
    // },

  
    search: function () {
      // console.log(typeof (this.search), 'fdzhfxgh')
    },
    tabcontrol(val) {
      if (val == "board") {
        this.tableList = false
        this.addTaskbtn = false
        this.showTask = false
        this.$store.commit("task/setAllTypesTasks", this.currentUserTasks);
      }
      else if (val == 'list') {
        this.tableList = true
        this.addTaskbtn = true
        this.$store.commit("task/setAllTypesTasks", this.totalTasks);
      }
    },
    taskSwitch(val) {
      if (val == "user") {
        this.$store.commit("task/setAllTypesTasks", this.currentUserTasks);
      } else if (val == "all") {
        this.$store.commit("task/setAllTypesTasks", this.totalTasks);
      } else if (val == "attached") {
        this.$store.commit("task/setAllTypesTasks", []);
      }
    },
    // showallusers: function(){
    //   this.ShowList = []
    //   if(this.showallusers){
    //     this.ShowList = this.taskLists
    //   }else{
    //    this.ShowList = individualTaskList
    //   }
    // }
  },
  created() {
    
    //  this.$store.dispatch("getTasklistNew")
    // this.projectdata = this.$store.state.projectName;
    // console.log(this.$store.state.projectName, "this.$store.state.projectName");
    var today = new Date();
    var tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    tomorrow.toLocaleDateString();
    var today = new Date().toISOString().substr(0, 10);
    this.date = today;
    this.toDate = today
    var days = 1;
    var dateStr = new Date();
    var result = new Date(
      new Date(dateStr).setDate(new Date(dateStr).getDate() + days));
    this.dueDate = result.toISOString().substr(0, 10);
    // dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
  },

  mounted() {
    this.intialMethod();
    // var message = this.commentdata + Array(120).fill('\xa0').join(' ') + this.commentdata

    this.$store.dispatch("task/getTasks")
    let empdata = JSON.parse(localStorage.getItem('role'))
    let empName = JSON.parse(localStorage.getItem('empName'))
    let validEmp = empdata.filter((el) => {
      return el.emp_name == empName
    })
    validEmp[0]['emp_role'] == 1 ? this.isAdmin = true : this.isAdmin = false

  },
  //Method to call Parse date//
  methods: {

    searchDetails() { },
    addTaskDetails() { },
    addColumn() { },
    searchCancel() {
      this.closeOnContentClick = true;
      this.projectdrop = ''
      this.customedrop = ''
      this.statusdrop = ''
      this.prioritydrop = ''
      this.searchAssignedData = ''

    },
    updateTask(task) {
      this.$store.commit("setCurrentTask", task);

    },
    selectAssigned(val) {
      this.assignedValue = val;
    },
    projectId(val) {
      this.managerdata = val;
    },
    addTask() {
      this.showTask = true
      this.tableList = false
      this.$store.commit("setManagerName", '');
      this.estimateTime = "";
      this.effortTime = "00:00"
      this.assignedEmployee = this.$store.state.userData.emp_id 
      this.hideTab = false
      this.taskkForm = false
    },
    caneclTask() {
      this.tableList = true
      this.showTask = false;
      this.taskName = "";
      this.projectdata = "";
      this.assignedEmployee = "";
      this.managerdata = "";
      this.descriptionDetail = "";
      this.estimateTime = "00:00";
      this.taskkForm = true
      this.submitted = false
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      return `${year}/${month.padStart(2, "0")}/${day.padStart(2, "0")}`;
    },
    async intialMethod() {
      await this.$store.dispatch("getProjectData");
      await this.$store.dispatch("getEmployeeData");
    },
    saveTask() {
      
      if(this.submitted = true){
      
      if (this.$refs.form.validate()) {
        if (this.estimateTime = '') {
           return false;
        }
      
        var currentDate = new Date(this.date);
        var startDate =
          currentDate.getDate() +
          "/" +
          (currentDate.getMonth() + 1) +
          "/" +
          currentDate.getFullYear();
        var endDate = new Date(this.dueDate);
        var dueDate =
          endDate.getDate().toString().padStart(2, "0") +
          "/" +
          (endDate.getMonth() + 1).toString().padStart(2, "0") +
          "/" +
          endDate.getFullYear();
        var timeData =
          this.estimateTime.split(":")[0] * 60 +
          this.estimateTime.split(":")[1] * 1;
        let json = {
          taskName: this.taskName,
          descriptionData: this.descriptionDetail == '' ? this.taskName : this.descriptionDetail,
          startDate: startDate,
          endDate: dueDate,
          status: this.currentstatus,
          estimate: timeData,
          assignedTo: this.assignedEmployee,
          managerID: this.managerdata,
          prioritystatus: this.prioritydata,
          projectType: this.projectdata,
        };
       
        this.$store.dispatch("createTaskDetails", json);
        this.showTask = false
        this.tableList = true
        this.taskName = '',
          this.descriptionDetail = '',
          this.projectdata = ''
        this.managerdata = ''
        this.taskkForm = true
        this.hideTab = false
        this.tableList = true
        this.submitted = false
        
      }
    
    }
    // this.submitted = false
    },
    getManager(proj) {
      this.$store.commit("setManagerName", proj);
    },
    updateTask(data) {
      this.updateTaskData = data
      this.assignedEmployee = this.$store.state.userData.emp_id
      this.priorityData = data.priority
      this.statusData = data.task_status
      this.dailog = true
    },
    close() {
      this.dailog = false
      this.commentdata = ''
      this.timeValid = true
      this.actualTime == 'hh:mm'
      this.$refs.updateForm.resetValidation()
      if(this.actualTime = this.actualTime){
        this.actualTime = '00:00'
      }
      
    },
    save() {
      
      this.timeValid = true
     
      if (this.$refs.updateForm.validate()) {
        // let comments = this.commentdata.length,
        //   space = 120;
        // console.log(this.commentdata.padStart(comments + space, ' '));
        //     this.commentdata =  this.commentdata + new Array(70 + 1).join(' ')
        // console.log(this.commentdata);
        this.dailog = false
        var actualeffort = this.actualTime.split(':')[0] * 60 + this.actualTime.split(':')[1] * 1;
        let json = {
          taskid: this.updateTaskData.id,
          comment: this.commentdata + Array(120).fill('\xa0').join(''),
          actualhour: actualeffort,
          assignedTo: this.assignedEmployee,
          priorityStatus: this.priorityData,
          taskStatus: this.statusData,
          id: this.updateTaskData.id
        }
        this.$store.dispatch("updateTaskDetails", json)
        this.$refs.updateForm.resetValidation()
        this.timeValid = false
        this.commentdata = ''
        if( this.actualTime = '00:00'){
      this.timeValid = false
    }
       
      }
      this.timeValid = false
     
    
    },
    drag_handler(evt, currentStatus, oldBasketId, newBasketId) {
      console.log(evt.draggedContext);
    },
    afterAdd(evt) {
      console.log(evt)
    },
    handleChange(openTasks, event) {
      console.log(event)
      let data = openTasks
      data.forEach((el => {
        if (el.project_id == el.project_id) {
          this.assignedData = el.assigned_to
          this.taskID = el.task_id
          this.actualHour = el.actual_hour
          this.prorityStatus = el.priority
          this.taskType = el.task_status
          this.uniqueID = el.id
        }
      }))

      var actualeffort = this.actualTime.split(':')[0] * 60 + this.actualTime.split(':')[1] * 1;
      let json = {
        taskid: this.taskID,
        comment: "Kanban                                                                                                                   Board",
        actualhour: this.actualHour,
        assignedTo: this.assignedData,
        priorityStatus: this.prorityStatus,
        taskStatus: this.taskType,
        id: this.uniqueID
      }
      console.log(this.updateTaskData);
      this.$store.dispatch("updateTaskDetails", json)
      this.$refs.form.resetValidation()
      this.actualTime = '00:00'
      this.commentdata = ''
    },

    handleChange2(inProgressTasks) {
      let data = inProgressTasks
      data.forEach((el => {
        if (el.project_id == el.project_id) {
          this.assignedData = el.assigned_to
          this.taskID = el.task_id
          this.actualHour = el.actual_hour
          this.prorityStatus = el.priority
          this.taskType = el.task_status
          this.uniqueID = el.id
        }

      }))
      let json = {
        taskid: this.taskID,
        comment: "Kanban                                                                                                                   Board",
        actualhour: this.actualHour,
        assignedTo: this.assignedData,
        priorityStatus: this.prorityStatus,
        taskStatus: this.taskType,
        id: this.uniqueID
      }

      this.$refs.form.resetValidation()
      this.actualTime = '00:00'
      this.commentdata = ''
    },

    handleChange3(fixedTasks) {
      let data = fixedTasks
      data.forEach((el => {
        if (el.project_id == el.project_id) {
          this.assignedData = el.assigned_to
          this.taskID = el.task_id
          this.actualHour = el.actual_hour
          this.prorityStatus = el.priority
          this.taskType = el.task_status
          this.uniqueID = el.id

        }

      }))
      let json = {
        taskid: this.taskID,
        comment: "Kanban                                                                                                                   Board",
        actualhour: this.actualHour,
        assignedTo: this.assignedData,
        priorityStatus: this.prorityStatus,
        taskStatus: this.taskType,
        id: this.uniqueID
      }
      this.$refs.form.resetValidation()
      this.actualTime = '00:00'
      this.commentdata = ''
    },


    handleChange4(completedTasks) {
      let data = completedTasks
      data.forEach((el => {
        if (el.project_id == el.project_id) {
          this.assignedData = el.assigned_to
          this.taskID = el.task_id
          this.actualHour = el.actual_hour
          this.prorityStatus = el.priority
          this.taskType = el.task_status
          this.uniqueID = el.id

        }

      }))
      let json = {
        taskid: this.taskID,
        comment: "Kanban                                                                                                                   Board",
        actualhour: this.actualHour,
        assignedTo: this.assignedData,
        priorityStatus: this.prorityStatus,
        taskStatus: this.taskType,
        id: this.uniqueID
      }
      this.$refs.form.resetValidation()
      this.actualTime = '00:00'
      this.commentdata = ''
    },



    minutesToHours(minutes) {
      var h = Math.floor(minutes / 60);
      var m = minutes % 60;
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      return h;
    },

    sortTasks() {
      this.sortingBool = !this.sortingBool;
      this.$store.commit("task/setSortTasks", this.sortingBool);
    },

    update(event, group) {
      console.log(group);
    },

    alltask(t) {
      console.log(t);
    }
  },
};
</script>

<style>
.v-application .orange--text {
  color: #ff9800 !important;
  caret-color: #ff9800 !important;
}

.LowColor {
  background-color: #4caf5099;
  border-radius: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 33px;
  padding-right: 33px;
  color: white;
}

.mediumColor {
  background-color: #f7fb0073;
  border-radius: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 20px;
  padding-right: 20px;
}

.greenColorCompleted {
  background-color: #fb8c00c4 !important;
  border-radius: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 31px;
  padding-left: 31px;
  color: white;
}

.blueColor {
  background-color: #ff5252a3;
  border-radius: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 23px;
  padding-left: 23px;
  color: white;
}

.cancelColor {
  background-color: #ff5252a3;
  border-radius: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 17px;
  padding-right: 17px;
}

.pointer {
  cursor: pointer;
}

.row-pointer>.v-data-table__wrapper>table>tbody>tr:hover {
  cursor: pointer;
}

.v-text-field>.v-input__control>.v-input__slot:before {
  border-style: none;
}

.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

input[type=time]::-webkit-datetime-edit-ampm-field {
  display: none !important;
}

input[type=time]::-webkit-clear-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
  margin: -10px;
}

.cardHead {
  background: #f5f5f5;
  min-height: 40px;
}

.taskCard {
  background: #f8f8f8 !important;
  box-shadow: inset 0 0 0 1px #f4f6ff !important;
  border: 1px solid rgb(0 0 0 / 4%) !important;
  cursor: move !important;
}

.h-550 {
  height: 550px;
}

.m-h-76 {
  min-height: 100px;
}

.mt-22 {
  margin-top: 22px !important;
}

/* The heart of the matter */
.testimonial-group>.boardRow {
  overflow-x: auto !important;
  white-space: nowrap !important;
}

.testimonial-group>.boardRow>.boards {
  display: inline-block !important;
  float: none !important;
}

.datePicker {
  width: 50%;
  border: 1px solid #e5e5e5 !important;
  text-indent: 2px;
  font-size: 10px;
  border-radius: 4px;
  color: #808080;
}


.align-search.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  display: none !important;
}

.custom-table.v-data-table {
  border-radius: 0px !important;
}

.img-width {
  width: 232px !important;
}

.vue__time-picker input.display-time {
  border: 1px solid #505962e0 !important;
  width: 21em !important;
  height: 40px !important;
  padding: 0.3em 0.5em !important;
  font-size: 1em !important;
  border-radius: 4px !important;
}

.vue__time-picker {
  display: inline-block;
  position: relative;
  font-size: 1em !important;
  width: 21em !important;
  font-family: sans-serif !important;
  vertical-align: middle !important;
}

.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  position: absolute;
  z-index: 999;
  top: calc(3.2em + 2px);
  left: 0;
  /* background: #9b2525; */
  -webkit-box-shadow: 0 1px 6px rgb(0 0 0 / 15%);
  box-shadow: 0 1px 6px rgb(0 0 0 / 15%);
  width: 10em;
  height: 10em;
  font-weight: normal;
}

.align-time.vue__time-picker input.display-time {
  border: 1px solid #505962e0 !important;
  width: 13em !important;
  height: 40px !important;
  padding: 0.3em 0.5em !important;
  font-size: 1em !important;
  border-radius: 4px !important;
}

.align-time.vue__time-picker {
  display: inline-block;
  position: relative;
  font-size: 1em !important;
  width: 12em !important;
  font-family: sans-serif !important;
  vertical-align: middle !important;
}

/* .is-invalid {
  border: 1px solid red !important;
} */
.text-danger {
  color: #ff0000 !important;
}

@media (min-width:960px) and (max-width:1705px) {
  .align-time.vue__time-picker {
  display: inline-block;
  position: relative;
  font-size: 1em !important;
  width: 8em !important;
  font-family: sans-serif !important;
  vertical-align: middle !important;
}

.align-time.vue__time-picker input.display-time {
  border: 1px solid #505962e0 !important;
  width: 8em !important;
  height: 40px !important;
  padding: 0.3em 0.5em !important;
  font-size: 1em !important;
  border-radius: 4px !important;
}

}
</style>
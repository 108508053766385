<template>
  <div>
    <v-container fluid class="pa-0 mt-0">
      <v-form ref="form">
        <v-card class="mx-5 my-5 custom-card">
          <v-row class="ma-0 pa-4 align-center lh-80">
            <v-col cols="2" class="pa-0">
              <h3 width="100" height="24" class=".text-lg-h6">
                Projects
              </h3>
            </v-col>
            <v-col class="pa-0" cols="1"></v-col>
            <v-col cols="6" class="pa-0">
              <v-text-field v-model="search" single-line dense hide-details=true outlined prepend-inner-icon="mdi-magnify"
                placeholder="Search"></v-text-field>
            </v-col>
            <v-col class="pa-0" cols="1"></v-col>

            <v-col cols="2" class="pa-0 d-flex justify-end">
              <v-btn depressed color="align-center justify-center  text-none pa-0 primary " height="40"
                min-width="155px" @click.native.stop="dailog = true">
                Add Project
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table :headers="headers" v-bind:items="this.projecttList" :value="this.projecttList" :search="search"
          item-key="name" class="elevation-1 px-0 mx-5 mb-5 subtitle" id="table">
          <template slot="this.projecttList" slot-scope="">
            <tr>
              <th v-for="(item, index) in this.projecttList" :key="index" class="tableRowHeight"></th>
            </tr>
          </template>
          <template v-slot:item="props">
            <tr @click="createProject(props.item)">
              <td>{{ props.item.project_name }}</td>
              <td>{{ props.item.no_of_tasks }}</td>
              <td>{{ props.item.no_of_overdue_tasks }}</td>
              <td>{{ props.item.cust_name }}</td>
              <td>{{ props.item.manager_name }}</td>
              <td>{{ props.item.na ? "NA" : "NA" }}</td>
              <td>{{ props.item.na ? "NA" : "NA"  }}</td>
              <td>{{ props.item.na ? "NA" : "NA" }}</td>
              <!-- <td></td> -->
            </tr>
          </template>
        </v-data-table>

        <v-dialog v-model="dailog" persistent max-width="720" elevation="0" flat>
          <v-card>
            <v-card-title class="headline justify-center white--text text" style="background-color: #0d263b">Create
              Project</v-card-title>
            <v-progress-linear :active="dailogloading" :indeterminate="dailogloading" rounded height="3"
              color="red darken-1"></v-progress-linear>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="12">
                    <div>Project Name</div>
                    <v-text-field dense maxlength="50" required :rules="inputRules" v-model="projectName" outlined>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="6">
                    <div>Project short Name</div>
                    <v-text-field dense :rules="shortnameRules" maxlength="10" required v-model="shortName" outlined>
                    </v-text-field>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="6">
                    <div>Customer Name</div>
                    <v-autocomplete :items="customerNames" dense required :rules="customernameRules"
                      item-text="cust_name" item-value="cust_id" v-model="customerName" outlined></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row> </v-row>
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="6">
                    <div>Manager Name</div>
                    <v-autocomplete :items="managerNames" dense :rules="projectnameRules" item-text="manager_name"
                      item-value="manager" required v-model="managerName" outlined></v-autocomplete>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="6">
                    <div>Type</div>
                    <v-autocomplete dense required v-model="typedetails" :items="typeList" outlined></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col cols="12" sm="6" md="6" lg="6" class="pr-0 pt-0 pb-0">
                    <v-row class="ma-0">
                      <v-col cols="12" sm="6" md="6" lg="6" class="pa-0 mt-4">
                        <div>Billable</div>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" class="pr-0 pt-0 pb-0">
                        <div class="mt-0" style="float: right !important">
                          <v-switch v-model="billableToggle" inset></v-switch>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed color="error" @click="close"> Cancel</v-btn>
              <v-btn depressed color="primary" @click="saveProductDetails">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import toaster from "../../mixins/expire";
export default {
  mixins: [toaster],
  data: () => ({
    search: "",
    customerData: [],
    customerName: "",
    typedetails: "Service",
    billableToggle: 1,
    menu1: false,
    dailog: false,
    managerName: "",
    projectName: "",
    shortName: "",
    getTask: [],
    valid: true,
    typeList: [
      "Service",
      "Product",
      "Product-customaization",
      "RFP",
      "Testing",
      "UI/UX Design",
    ],
    headers: [
      { text: "Project Title", value: "project_name", sortable: true },
      { text: "No.of.task", value: "no_of_tasks", sortable: true },
      { text: "Overdue task", value: "no_of_overdue_tasks", sortable: true },
      { text: "Customer Name", value: "cust_name", sortable: true },
      { text: "Manager Name", value: "manager_name", sortable: true },
      { text: "No of Resource", value: "na", sortable: true },
      { text: "Hours Spent", value: "na", sortable: true },
      { text: "Comments", value: "na", sortable: true },
      
    ],
    inputRules: [(v) => !!v || "Projectname is required"],
    shortnameRules: [(v) => !!v || "Shortname is required"],
    customernameRules: [(v) => !!v || "Customername is required"],
    projectnameRules: [(v) => !!v || "Managername is required"],
  }),

  computed: {
    ...mapState(["projecttList", "managerNames", "customerNames"]),
    ...mapState(["dailogloading"]),
  },
  watch: {},
  created() {
    this.$store.dispatch("getProjectData");
    this.$store.dispatch("getCustomerData");
  },
  mounted() { },
  methods: {
  
    close() {
      this.dailog = false;
      this.$refs.form.resetValidation();
      this.projectName = '',
      this.shortName = '',
      this.customerName = '',
      this.managerName = ''
    },
    createProject(data) {
      //  this.$router.push('/Task')
    },
    saveProductDetails() {
      if (this.$refs.form.validate()) {
       this.dailog = false;
        let json = {
          project: this.projectName,
          projShortName: this.shortName,
          cusName: this.customerName,
          managerId: this.managerName,
          typeInfo: this.typedetails,
          toggleEnable: this.billableToggle,
        };
        this.$store.dispatch("insertProject", json);
        
      }
    },
  },
};
</script>

<style>
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
  background: white;
}

.fontfamily {
  font-family: "Lato";
}

.v-data-table ::v-deep th {
  font-size: 14px !important;
  color: black !important;
}

/* ::v-deep .v-data-table__empty-wrapper {
  display: none !important;
} */
.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  font-size: inherit;

}

.cls_btn {
  bottom: 9px;
}

.v-messages {
  flex: 1 1 auto;
  font-size: 14px;
  min-height: 14px;
  min-width: 1px;
  position: relative;
  margin-left: -8px !important;
}

.v-data-table {
  border-radius: 12px !important;
}

.custom-card.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 5%) !important;
}


</style>
<template>
    <div>
       
 <div class="ml-3 mt-6">
     <h4>
         Employee-Login
     </h4>
 </div>

  <v-row>
     
      <v-col  cols="12">
          <v-card>
              <v-row>
                  <v-col cols="12" sm="12" lg="2" class="pt-8 pb-8">
                    <v-text-field outlined v-model="userID" label="User-ID" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" lg="2" class="pt-8 pb-8">
                  
                    <v-menu
        v-model="menu2"
        :close-on-content-click="false"
       
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            outlined
            label="Date"
            prepend-outer-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" lg="2" class="pt-8 pb-8">
                        <v-text-field outlined v-model="Time" label="Time"  type ="time" required></v-text-field>
                    </v-col>
                   <v-col cols="12" sm="12" lg="2" class="pt-8 pb-8">
                    <v-switch 
      v-model="switch1" color="green"
      inset
      :label="`CHECK-IN`" :value="true" @change="trackingStateChanged()"
    ></v-switch>
                   </v-col>
                   <v-col cols="12" sm="12" lg="2" class="pt-8 pb-8">
                    <v-switch
      v-model="switch2" color="red"
      inset
      :label="`CHECK-OUT`"
    ></v-switch>
                   </v-col>
              </v-row>
          </v-card>
        </v-col>
       
  </v-row>
    </div>
</template>
<script>
export default {
  data: () => ({
      switch1: false,
      switch2: false,
      menu2: false,
      userID:'',
      date:'',
      Time: '',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
  }),

  computed:{

  },
  watch:{
    
  },
  mounted(){
    
  },
 
  methods:{
    trackingStateChanged(val){
       if(val == true){
        this.switch2 = false
       }
    }
  }
};
</script>

<style scoped>
.v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-top: 0px;
    margin-bottom: 0px !important;
    display: none !important;
}
</style>
<template>
  <div>
    <v-container fluid class="pa-0 mt-0">
      <v-form ref="form">
         <v-card class="mx-5 my-5 custom-card">
        <v-row class="ma-0 pa-4 align-center lh-80">
          <v-col cols="2" class="pa-0">
            <h3 width="100" height="24" class=".text-lg-h6">Customer</h3>
          </v-col>
          <v-col class="pa-0" cols="1"></v-col>
          <v-col cols="6" class="pa-0">
            <v-text-field v-model="search" single-line hide-details=true dense outlined prepend-inner-icon="mdi-magnify"
              placeholder="Search"></v-text-field>
          </v-col>
          <v-col class="pa-0" cols="1"></v-col>

          <v-col cols="2" class="pa-0 d-flex justify-end ">
            <v-btn depressed color="align-center justify-center  text-none pa-0 primary " height="40" min-width="155px"
              class="" @click.native.stop="dailog = true">
              Add Customer
            </v-btn>
          </v-col>
        </v-row>
        </v-card>
        <v-data-table :headers="headers" v-bind:items="this.customerLists" :search="search" :value="this.customerLists"
          item-key="name" class="elevation-1  mx-5" id="table">
          <template slot="this.customerList" slot-scope="">
            <tr>
              <th v-for="(item, index) in this.customerLists" :key="index" class="tableRowHeight"></th>
            </tr>
          </template>
          <template v-slot:item="props">
            <tr v-if="props.item.cust_name != ' '">
              <td>{{ props.index + 1 }}</td>
              <td>{{ props.item.cust_name }}</td>
              <td>{{ props.item.cust_short_name }}</td>
              <td>{{ props.item.address_1 }}</td>
              <td>{{ props.item.address_2 }}</td>
              <td>{{ props.item.contact_mobile }}</td>
              <td>{{ props.item.contact_email }}</td>
              <td>{{ props.item.spoc_name }}</td>
            </tr>
          </template>
        </v-data-table>
        <v-dialog v-model="dailog" persistent max-width="720" elevation="0" flat>
          <v-card>
            <v-card-title class="headline justify-center white--text text" style="background-color: #0D263B;">Create
              Customer</v-card-title>
            <v-card-text>
              <v-container fluid>

                <v-row>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="12">
                    <div>Customer Name</div>
                    <v-text-field dense :rules="nameRules" required v-model="customerName" outlined></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="6">
                    <div>Customer short Name</div>
                    <v-text-field dense :rules="shortnameRules" required v-model="customerShortName" outlined>
                    </v-text-field>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="6">
                    <div>SPOC Name</div>
                    <v-text-field dense :rules="spocnameRules" required v-model="spocNumber" outlined></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="12">
                    <div>Address Line 1</div>
                    <v-textarea dense :rules="addressOne" required v-model="addressLineone" outlined name="input-0-2"
                      height="50" value=""></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="12">
                    <div>Address Line 2</div>
                    <v-textarea dense :rules="addressTwo" required v-model="addressLinetwo" outlined name="input-0-8"
                      height="50" value=""></v-textarea>
                  </v-col>
                </v-row>
                <v-row> </v-row>
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="6">
                    <div>Contact Number</div>
                    <v-text-field dense required v-model="contactNumber" :rules="mobileNumber" outlined></v-text-field>
                  </v-col>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="6">
                    <div>Email</div>
                    <v-text-field dense :rules="emailRules" required v-model="userEmail" outlined></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 pb-0" cols="12" sm="6" md="4" lg="6">
                    <div>Choose Logo</div>
                    <v-file-input dense append-icon="mdi-folder-open" prepend-icon="" outlined></v-file-input>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed color="error" @click="close">Cancel</v-btn>
              <v-btn depressed color="primary" @click="saveCustomerDetails">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- </div> -->
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import toaster from '../../mixins/expire'
export default {
  mixins: [toaster],

  data: () => ({
    customerName: "",
    customerShortName: "",
    addressLineone: "",
    addressLinetwo: "",
    contactNumber: "",
    userEmail: "",
    spocNumber: "",
    dailog: false,
    search: '',

    headers: [
      { text: "S.NO", align: "start", sortable: false, value: "cust_name", class: "", },
      { text: "Customer Name", value: "cust_name ", sortable: false },
      { text: "Customer Shortname", value: "cust_short_name", sortable: false },
      { text: "Address One", value: "address_1", sortable: false },
      { text: "Address Two", value: "address_2", sortable: false },
      { text: "Mobile Number", value: "contact_mobile", sortable: false },
      { text: "Email ID", value: "contact_email", sortable: false },
      { text: "SPOC Name", value: "spoc_name", sortable: false },
    ],

    shortnameRules: [
      v => !!v || "Short Name is required",
      v => v.length <= 10 || "Short Name must be less than 10 characters",
    ],
    nameRules: [
      v => !!v || "Name is required",
      v => v.length <= 50 || "Name must be less than 50 characters",
    ],
    spocnameRules: [
      v => !!v || " SPOC Name is required",
    ],
    addressOne: [
      v => !!v || "Addressone is required",
    ],
    addressTwo: [
      v => !!v || "Addresstwo is required",
    ],
    mobileNumber: [
      v => !!v || "Contact Number is required",
      v => v.length <= 10 || "Contact Number must be less than 10 characters",
    ],
    emailRules: [
      v => !!v || "E-mail is required",
      v =>
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail must be valid",
    ],
  }),

  computed: {
    ...mapState(["customerLists"]),
    customerData() {
      return this.$store.state.customerLists;
    },
      
  },

  

  created() {
    this.$store.dispatch("getCustomerData")
  },

  methods: {
    close() {
      this.dailog = false;
      this.$refs.form.resetValidation()
      this.customerName = ''
      this.customerShortName = ''
      this.spocNumber = ''
      this.addressLineone = ''
      this.addressLinetwo = ''
      this.mobileNumber = ''
      this.userEmail = ''
      this.contactNumber = ''
    },

    saveCustomerDetails() {
      if (this.$refs.form.validate()) {
        this.dailog = false
        let json = {
          customerData: this.customerName,
          customerShortName: this.customerShortName,
          addressOne: this.addressLineone,
          addressTwo: this.addressLinetwo,
          mobileNumber: this.contactNumber,
          customerEmail: this.userEmail,
          spocNumber: this.spocNumber,
        };

        this.$store.dispatch("insertCustomer", json);

         this.customerName = '',
          this.customerShortName = '',
          this.addressLineone = '',
          this.addressLinetwo = '',
          this.contactNumber = '',
          this.userEmail = '',
          this.spocNumber = ''
      }
    },
  }
};
</script>

<style>
.v-messages {
  flex: 1 1 auto;
  font-size: 14px;
  min-height: 14px;
  min-width: 1px;
  position: relative;
  margin-left: -8px !important;
}
.v-data-table {
    border-radius: 12px !important;
}
.custom-card.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 5%) !important;
}

</style>
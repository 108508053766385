<template>
  <div>
    <v-row class="ma-0 pa-4 fsize20">
      <h4>Teams</h4>
    </v-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
<template>
    <div>
        <div>
            <div class="pa-6">
                <h4>Daily Check-In Status</h4>
            </div>
            <v-row class="d-flex align-center justify-center">
                <img src="@/assets/icons/nodata.svg" class="img">

            </v-row>
            <div class="d-flex align-center justify-center">
                <p class="fsize24 font-bold"> No Status Found</p>
            </div>
                  
        </div>
        <!-- <div>
            <label for="" class="fsize16 px-2 py-1 label-color">SS</label>
        </div> -->
        <!-- <v-row>

            <v-col cols="12">
                <div class="">
                    <h4>Daily Check-In Status</h4>
                </div>
                <v-form ref="form">
                    <v-card class="mt-4">

                        <div class="d-flex justify-space-evenly">

                            <div class="px-4 py-4">

                                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false"
                                    transition="scale-transition" offset-y max-width="290px" min-width="auto"
                                    hide-details=true>
                                    <template v-slot:activator="{ on, attrs }">

                                        <v-text-field style="max-width: 300px" outlined label="Choose Date"
                                            :rules="dateRules" v-model="dateFormatted" dense show-current
                                            persistent-hint append-icon="mdi-calendar" v-bind="attrs" hide-details=true
                                            v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="date" no-title :allowed-dates="
                                        (date) => date <= new Date().toISOString().substr(0, 10)
                                    " @input="menu1 = false"></v-date-picker>
                                </v-menu>

                            </div>
                            <div class="mt-4">
                                <v-btn width="80" height="40" color="primary" @click="searchData()">
                                    Submit
                                </v-btn>
                            </div>
                        </div>
                        <div class="px-2 py-2">
                            <v-chart style="height:450px" autoresize :options="getUserCount" />
                        </div>
                    </v-card>
                </v-form>
            </v-col>

        </v-row>
        <v-row class="px-0 py-0">
            <v-col cols="12">
                <div class="">
                    <h4>Online Status</h4>
                </div>
                <v-card>
                    <v-simple-table class="mt-4 custom-table">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-start">
                                        Name
                                    </th>
                                    <th class="text-center">
                                        Checkin-Time
                                    </th>
                                    <th class="text-center">
                                        Checkout-Time
                                    </th>
                                    <th class="text-left">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(idx, item) in userStatus" :key="item">
                                    <td class="text-start"><span>
                                            <v-avatar color="warning lighten-2">

                                                <img src="https://randomuser.me/api/portraits/men/78.jpg" alt="John">
                                            </v-avatar>
                                        </span><span class="ml-2">{{ idx.name }}</span></td>
                                    <td class="text-center">{{ idx.checkin }}</td>
                                    <td class="text-center">{{ idx.checkout }}</td>
                                    <td><span>
                                            <v-icon
                                                :color="idx.status === 'Online' ? 'green' : idx.status === 'Offline' ? 'red' : 'orange'">
                                                {{ idx.icon }}</v-icon>
                                            </span><span style="margin-left: 12px;
    vertical-align: inherit;">{{ idx.status }}</span></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row> -->
        <div class="ml-8">
            <h4>On-line Status</h4>
        </div>

    
        <div class="ml-4">
            <span v-for="(item, index) in TaskhourList" :key="index" right>
                <v-tooltip bottom v-if="!item.check_out">
                    <template v-slot:activator="{ on }">
                        
                            <v-avatar v-on="on" class="white--text text-h6"
                                :color="item.check_in == 1 && item.check_out == 0 ? '#01df02' : item.check_out == 1 ? '#fd0001' : ''">
                                {{  item.emp_name[0]}} 
                            </v-avatar>
                     
                    </template>
                    <span>{{item.emp_name}} is Online now</span>

                </v-tooltip>
            </span>
        </div>
       
        <v-row>
            <div class="ml-4">
                <div class="mt-4 ml-2">
            <h4>Off-line Status</h4>
        </div>
            <span v-for="(item, index) in TaskhourList" :key="index" right>
                <v-tooltip bottom v-if="item.check_out">
                    <template v-slot:activator="{ on }">
                       
                            <v-avatar v-on="on" class="white--text text-h6"
                                :color="item.check_in == 1 && item.check_out == 0 ? '#01df02' : item.check_out == 1 ? '#fd0001' : ''">
                                {{  item.emp_name[0] }} 
                            </v-avatar>
                      
                    </template>
                    <span>{{item.emp_name}} is Offline now</span>

                </v-tooltip>
            </span>
        </div>
        </v-row>
 

             
        <!-- <v-row>
            <div class="ml-4">
                <div class="mt-4 ml-2">
            <h4>Absent Status</h4>
        </div>
            <span v-for="(item, index) in activeList" :key="index" right>
                <v-tooltip bottom v-if="item.in_Time == null && item.out_Time == null">
                    <template v-slot:activator="{ on }">
                       
                            <v-avatar v-on="on" class="white--text text-h6"
                                :color="item.in_Time == null && item.out_Time == null ? '#fd0001' :  '' ">
                                {{  item.emp_name[0] }} 
                            </v-avatar>
                      
                    </template>
                    <span>{{item.emp_name}} in Leave</span>

                </v-tooltip>
            </span>
        </div>
        </v-row> -->
        <br>
    </div>
</template>

<script>

import { mapGetters, mapState } from "vuex"
import "@/plugins/echart";
import loginVue from './login.vue';
export default {
    components: {
        // VChart,
        // apexchart: VueApexCharts,
    },

    data: () => ({
        menu1: false,
        date: "",
        checkinId: "",
        checkoutId: "",
        toDate: "",
        dateRules: [(v) => !!v || "Date is required.",],


        // chartOptions: {
        //     chart: {
        //         height: 350,
        //         type: 'bar',
        //         zoom: {
        //             enabled: false
        //         },
        //         animations: {
        //             enabled: false
        //         }
        //     },
        //     stroke: {
        //         width: [5, 5, 4],
        //         curve: 'smooth'
        //     },
        //     // labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        //     title: {
        //         text: ''
        //     },
        //     xaxis: {
        //          categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        //     },
        //     toolbar: {
        //         show: false,
        //         tools: {
        //             download: false // <== line to add
        //         }
        //     }
        // },

    }),

    computed: {
        //    ...mapGetters([
        //       GET_USER_COUNT: "getUserCount",
        //   }),
      
        ...mapGetters({ empList: "getEmployeData" , activeList : "getActiveList" }),
        dateFormatted() {
            return this.formatDate(this.date);
        },
        ...mapState(["reportItems", "hoursData", "TaskhourList", "allComments"]),
       
    },

    mounted() {
        var fromDate = new Date().toLocaleDateString('en-GB')
        var toDate = new Date().toLocaleDateString('en-GB')
        this.checkinId = (localStorage.getItem('checkin'));
        this.checkoutId = (localStorage.getItem('checkoutid'));
       
        let json = {
            currentDate: fromDate,
            endDate: toDate,
            dateProvided: "true",
        }
          let jsonData = {
            "userdate" : "2022-03-15"
          }
        this.$store.dispatch("getHoursList", json);
        // this.$store.dispatch("userActive", jsonData);


    },

    methods: {
        // searchData() {
        //     if (this.$refs.form.validate()) {
        //         let jsonObj = {
        //             "userDate": this.date
        //         }
        //         let json = {
        //             "userdate": this.date
        //         }
        //         this.$store.dispatch("userCount", jsonObj)
        //         this.$store.dispatch("userActive", json)
        //     }
        // },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },

    }
};
</script>

<style>
.custom-table.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
    font-size: 14px !important;
    height: 64px !important;
}

.apexcharts-toolbar {
    position: absolute;
    z-index: 11;
    max-width: 176px;
    text-align: right;
    border-radius: 3px;
    padding: 0px 6px 2px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none !important;
}

.v-sheet.v-card {
    border-radius: 12px !important;
}

.v-simple-table {
    border-radius: 12px !important;
}

.img {
    width: 28% !important;
}

.align-avatar {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    word-spacing: normal;
    flex-wrap: wrap;
    margin: 10px;
    background: space;
}

.v-avatar {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    line-height: normal;
    position: relative;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 12px;
}


</style>